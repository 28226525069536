//dd
import { Appwrite, Account, Storage } from "@pankod/refine-appwrite";

const APPWRITE_URL = "https://appwrite.lehrerzimmer.io/v1";
const APPWRITE_PROJECT = "62df9ea8bc18d1a97ae3"

const appwriteClient = new Appwrite();


appwriteClient
    .setEndpoint(APPWRITE_URL)
    .setProject(APPWRITE_PROJECT)

// for authentication
const account = new Account(appwriteClient);
// for file upload
const storage = new Storage(appwriteClient);
const promise = account.create('[USER_ID]', 'email@example.com', 'password');
export { appwriteClient, account, storage };