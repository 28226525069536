import { Authenticated, Refine } from "@pankod/refine-core";
import {
  notificationProvider,
  AntdLayout,
  ReadyPage,
  ErrorComponent,
} from "@pankod/refine-antd";
import "@pankod/refine-antd/dist/styles.min.css";
import routerProvider from "@pankod/refine-react-router-v6";
//import dataProvider from "@pankod/refine-simple-rest";
// dataProvider={dataProvider("https://api.fake-rest.refine.dev")}
import { Posts } from "components/Posts";
import { dataProvider, liveProvider } from "@pankod/refine-appwrite";
import { appwriteClient } from "utilities/appwriteClient";
import { authProvider } from "utilities/authProvider";

import {  PostShow, PostEdit, PostCreate, PostsList} from "pages/posts";
import { CustomSider } from "components";
import { Login } from "pages/login/login";

const {Link} = routerProvider



const App: React.FC = () => {
  return (
    <Refine
    dataProvider={dataProvider(appwriteClient, {
      databaseId: "62df9eb865ada6d8863a",
  })}
    liveProvider={liveProvider(appwriteClient, {
    databaseId: "62df9eb865ada6d8863a",
  })}
      authProvider={authProvider}
      routerProvider={{
        ...routerProvider,
        routes: [
             
        ],
    }}
      notificationProvider={notificationProvider}
      LoginPage={Login}
      resources={[
        { 
          name: "62df9f21115015afad2d", 
          list: PostsList, 
          edit: PostEdit, 
          show: PostShow,
          create: PostCreate,
          canDelete: true,
          options: {
          label: "Upload"
          }
        },
      ]}
      Layout={({ children, Footer, OffLayoutArea}) => (
        <Authenticated>
        <AntdLayout>
          <AntdLayout.Header>
            <CustomSider />
          </AntdLayout.Header>
          <AntdLayout.Content>
            <AntdLayout.Content>
            <div style={{ padding: 24, minHeight: 360}}>
              {children}
            </div>
          </AntdLayout.Content>
          {Footer && <Footer />}
          </AntdLayout.Content>
          {OffLayoutArea && <OffLayoutArea />}
        </AntdLayout>
        </Authenticated>
      )}
      Title={() => (
        <Link to="/" style={{ float: "left", marginRight: "10px" }}>
          <img src="/2u6jlI01.svg" alt="QMS" style={{ width: "3em"}}
          />
        </Link>
      )}
      ReadyPage={ReadyPage}
      liveMode="auto"
      catchAll={<ErrorComponent />}
    />
  );
}

export default App;
