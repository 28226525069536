
import { useMany, IResourceComponentsProps, CrudFilters, HttpError, getDefaultFilter } from "@pankod/refine-core";
import {
    List,
    useSimpleList,
    useSelect,
    Form,
    Row,
    Col,
    Button,
    Icons,
    Input,
    Table,
    useTable,
    Space,
    DatePicker,
    NumberField,
    FilterDropdown,
    Radio,
    TagField,
    Tag,
    EditButton,
    ShowButton,
    Typography,
    DeleteButton,
    getDefaultSortOrder,
    Select
} from "@pankod/refine-antd";
import {
    LockOutlined,
    LockTwoTone,
    UnlockOutlined
  } from '@ant-design/icons';
  

import { IPost } from "interfaces";
// import { Dayjs } from "dayjs";
// import { time } from "console";
const { RangePicker } = DatePicker; 
// import { FilterDropdownProps } from "antd/lib/table/interface";


// const { Title, Text } = Typography;

var authEdit: boolean;
function authcheck ()
{if (sessionStorage.getItem('auth')==process.env.REACT_APP_ADMIN_TEACHER  || sessionStorage.getItem('auth')==process.env.REACT_APP_ADMIN_DEVS)
{return false}
else 
{return true}
}
authEdit = authcheck();
var access: string|null = "none"
if (sessionStorage.getItem('auth')==process.env.REACT_APP_ADMIN_TEACHER  || sessionStorage.getItem('auth')==process.env.REACT_APP_ADMIN_DEVS)
{access = null}
else 
{if (sessionStorage.getItem('auth')!=null)
    {
        access=sessionStorage.getItem('auth')
    }}

    
    
export const PostsList: React.FC<IResourceComponentsProps> = () => {
    const { searchFormProps, tableProps, filters, sorter } = useTable<IPost, HttpError, { attribute: string, title: string; Zeitraum: number }>({
        onSearch: (params) => {
            const filters: CrudFilters = [];
            const { attribute, title, Zeitraum } = params;
            (document.getElementById("suchen") as HTMLButtonElement).disabled = true;
            filters.push(
                {
                    field: attribute,
                    operator: "contains",
                    value: title.toLowerCase(),
                } ,
                {
                    field: "$createdAt",
                    operator: "gt",
                    value: Zeitraum

                } 
                /*
                {
                    field: "$createdAt",
                    operator: "gte",
                    value: createdAt ? createdAt[0].toISOString() : undefined,
                },
                {
                    field: "$createdAt",
                    operator: "lte",
                    value: createdAt ? createdAt[1].toISOString() : undefined,
                }, 
                */
            );
            return filters;
        },
        //InitialFilter?
        initialFilter: [
            {
                field: "author",
                operator: "eq",
                value: access //pass loged in username var
            }, 
    ]
    });

    // const { selectProps } = useSelect<IPost>({
    //     resource: "posts",
    //     optionLabel: "title",
    //     optionValue: "id",
    //     defaultValue: getDefaultFilter("category.id", filters, "in"),
    // });
    
    /* const { tableProps, sorter} = useTable<IPost>({
        initialSorter: [
            {
                field: "$id",
                order: "asc",
            },
        ],
        initialFilter: [
            {
                field: "author",
                operator: "eq",
                value: access //pass loged in username var
            }, 
    ]
    }); */
    return (
        <div>
            <Row gutter={[16, 16]}>
            <Col lg={6} xs={24}>
                <Form layout="vertical" {...searchFormProps}>
                <Form.Item label="Suche in" name="attribute" >
                        <Select
                            placeholder="..."
                            id="auswahl"
                            options={[
                                {
                                    label: "Autor",
                                    value: "author",
                                    
                                },
                                {
                                    label: "Titel",
                                    value: "title",
                                    
                                },
                                {
                                    label: "Unterkategorie",
                                    value: "CustomCats".toString()
                                },
                                {
                                    label: "Beschreibung",
                                    value: "content"
                                }
                            ]}
                        />
                    </Form.Item>
                    <Form.Item label="Suchen" name="title">
                        <Input
                            placeholder="ID, Title, Content, etc."
                            prefix={<Icons.SearchOutlined />}
                        />
                    </Form.Item>
                    <Form.Item label="Zeitraum" name="time" >
                        <Select
                            options={[
                                {
                                    label: "heute",
                                    value: (Date.now()/1000)-86400
                                    
                                },
                                {
                                    label: "letzte Woche",
                                    value: (Date.now()/1000)-604800
                                    
                                },
                                {
                                    label: "letzte 14 Tage",
                                    value: (Date.now()/1000)-1209600
                                    
                                },
                                {
                                    label: "letzter Monat",
                                    value: (Date.now()/1000)-2419200
                                },
                                {
                                    label: "Alle",
                                    value: 1
                                }
                            ]}
                        />
                        </Form.Item>                  
                <Form.Item>
                        <Button htmlType="submit" type="primary" id="suchen">
                            Filter
                        </Button>
                        <Button onClick={()=>window.location.reload()} type="primary">
                            Filter zurücksetzen 
                        </Button>
                    </Form.Item>
                </Form>
            </Col>
            <Col lg={18} xs={24}>

            <List   canCreate={authEdit} >
            <Table {...tableProps} rowKey="id">
                {/* <Table.Column
                    dataIndex="id"
                    title="ID"
                    sorter
                    defaultSortOrder={getDefaultSortOrder("id", sorter)}
                /> */}
                <Table.Column dataIndex="title" title="Title" sorter />
                <Table.Column dataIndex="author" title="Autor" sorter/>
                <Table.Column 
                dataIndex="CategoryId" 
                title="Kategorie" 
                //sorter
                />
                <Table.Column dataIndex="RatingGesGes" title="Bewertung" sorter />
                <Table.Column 
                dataIndex="edubbSync" 
                title="Upload Status"
                render={(value) => {
                    let color;
                    switch (value) {
                        case "Nicht synchronisiert":
                            color = "yellow";
                            break;
                        case "error":
                            color = "red";
                            break;
                        case "Synchronisiert":
                            color = "green";
                            break;
                        default:
                            color = "";
                            break;
                    }
                    return <Tag color={color}>{value}</Tag>;
                }}
                //render={(value: string) => <TagField value={value} />}
                sorter/>
                <Table.Column 
                dataIndex="status" 
                title="Status"
                render={(value) => {
                    let color;
                    switch (value) {
                        case "published":
                            color = "green";
                            break;
                        case "rejected":
                            color = "red";
                            break;
                        case "draft":
                            color = "blue";
                            break;
                        default:
                            color = "";
                            break;
                    }
                    return <Tag color={color}>{value}</Tag>;
                }}
                //render={(value: string) => <TagField value={value} />}
                sorter/>

<Table.Column 
                dataIndex="upload_status" 
                title="Edu"
                render={(value) => {
                    let colorUp;
                    let icon;
                    switch (value) {
                        case "error":
                            colorUp = "red";
                            icon = "❌"
                            break;
                        case "success":
                            colorUp = "green";
                            icon = "✅"
                            break;
                        default:
                            colorUp = "grey";
                            icon = "❔"
                            break;
                    }
                    return <Tag color={colorUp}>{icon}</Tag>;
                }}
                //render={(value: string) => <TagField value={value} />}
                sorter/>

                <Table.Column 
                dataIndex="drittProviderBool" 
                title="Drittanbieter" 
                
                render={(value: boolean) => value ? <LockTwoTone /> : <UnlockOutlined />}
                // filterDropdown={(props: FilterDropdownProps) => (
                //     <FilterDropdown {...props}>
                //         <Radio.Group>
                //             <Radio value="true">Drittanbieter</Radio>
                //             <Radio value="false">Kein Drittanbieter</Radio>
                //         </Radio.Group>
                //     </FilterDropdown>
                // )}
                // defaultFilteredValue={getDefaultFilter(
                //     "category.id",
                //     filters,
                //     "in",
                // )}
                //sorter
                />
                <Table.Column dataIndex="date" title="Datum" sorter/>
                <Table.Column<IPost>
                    title="Aktionen"
                    dataIndex="actions"
                    render={(_, record) => (
                        <Space>
                            <EditButton
                                hideText
                                size="small"
                                recordItemId={record.id}
                            />
                            <ShowButton
                                hideText
                                size="small"
                                recordItemId={record.id}
                            />
                            <DeleteButton
                                hideText
                                size="small"
                                recordItemId={record.id}
                            />
                        </Space>
                    )}
                />
            </Table>
            <h5> nicht die erwarteten Ergebnisse? Ein Refresh der Seite könnte helfen </h5>
        </List>

        </Col>
        </Row>
        
        
        </div>
    );

};

