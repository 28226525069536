import React from "react";
import { useLogin } from "@pankod/refine-core";

import {
    Row,
    Col,
    AntdLayout,
    Card,
    Typography,
    Form,
    Input,
    Button,
    Checkbox,
} from "@pankod/refine-antd";
import "./styles.css";

const { Text, Title } = Typography;

export interface ILoginForm {
    username: string;
    password: string;
    remember: boolean;
}

export const Login: React.FC = () => {
    const [form] = Form.useForm<ILoginForm>();

    const { mutate: login } = useLogin<{username: string; password: string}> ();
    const { mutate: signup } = useLogin<{username: string; password: string}> (); //work

    const CardTitle = (
        <Title level={3} className="title">
            Bitte mit Account anmelden
        </Title>
    );
    const CardTitle2 = (
        <Title level={3} className="title">
            
        </Title>
    );

    return (
        <AntdLayout className="layout">
            <Row
                justify="center"
                align="middle"
                style={{
                    height: "100vh",
                }}
            >
                <Col xs={22}>
                    <div className="container">
                        <div className="imageContainer">
                            <img src="./2u6jlI01.svg" alt="QMS Logo" />
                        </div>
                        <Card title={CardTitle} headStyle={{ borderBottom: 0 }}>
                            <Form<ILoginForm>
                                layout="vertical"
                                form={form}
                                onFinish={(values) => {
                                    login(values);
                                }}
                                requiredMark={false}
                                initialValues={{
                                    remember: false,
                                    email: "Nutzername@email.de",
                                    password: "Password",
                                }}
                            >
                                <Form.Item
                                    name="email"
                                    label="Email"
                                    rules={[{ required: true, type: "email" }]}
                                >
                                    <Input size="large" placeholder="Email" />
                                </Form.Item>
                                <Form.Item
                                    name="password"
                                    label="Password"
                                    rules={[{ required: true }]}
                                    style={{ marginBottom: "12px" }}
                                >
                                    <Input
                                        type="password"
                                        placeholder="●●●●●●●●"
                                        size="large"
                                    />
                                </Form.Item>
                                <div style={{ marginBottom: "12px" }}>
                                    <Form.Item
                                        name="remember"
                                        valuePropName="checked"
                                        noStyle
                                    >
                                        <a href="https://registration-qms.vercel.app/ForgotPassword">
                                    Passwort vergessen?
                                    </a>
                                    <br></br>
                                       {/*  <Checkbox
                                            style={{
                                                fontSize: "12px",
                                            }}
                                        >
                                            merken
                                        </Checkbox> */}
                                    </Form.Item>

                                    <a href="https://registration-qms.vercel.app/register">
                                    Einen neuen Account erstellen
                                    </a>
                                </div>
                                <Button
                                    type="primary"
                                    size="large"
                                    htmlType="submit"
                                    block
                                >
                                    Anmelden
                                </Button>
                                
                            </Form>

                        </Card>
                    </div>
                </Col>
            </Row>
        </AntdLayout>
    );
};
