import { AuthProvider } from "@pankod/refine-core";

import { account } from "./appwriteClient";

export const authProvider: AuthProvider = {
    login: async ({ email, password }) => {
        try {
            await account.createEmailSession(email, password);
                if (email) {
                var storepls=JSON.stringify(email)
                sessionStorage.setItem('auth', storepls.replace(/['"]+/g, ''));
            }
            return Promise.resolve();
        } catch (e) {
            return Promise.reject();
        }
    },
    logout: async () => {
        await account.deleteSession("current");
        sessionStorage.removeItem('auth');

        return "/";
    },
    checkError: () => Promise.resolve(),
    checkAuth: async () => {
        const session = await account.get();

        if (session) {
            return Promise.resolve();
        }

        return Promise.reject();
    },
    getPermissions: () => Promise.resolve(),
    getUserIdentity: async () => {
        const user = await account.get();

        if (user) {
            return user;
        }
    },
};