import React, {useState} from "react";

import { IResourceComponentsProps } from "@pankod/refine-core";
import { useForm, Form, Input, Select, Edit, useSelect, TextField } from "@pankod/refine-antd";
import { useGetIdentity, usePermissions} from "@pankod/refine-core";
import "antd/dist/antd.css";


import { IPost} from "interfaces";
import { appwriteClient } from "utilities/appwriteClient";



export const PostEdit: React.FC<IResourceComponentsProps> = () => {
    const { formProps, saveButtonProps, queryResult } = useForm<IPost>();
    const { data: permissionsData } = usePermissions();
    const postData = queryResult?.data?.data;


    var canEdit = true;
    if (sessionStorage.getItem('auth')==process.env.REACT_APP_ADMIN_TEACHER 
        || sessionStorage.getItem('auth')==process.env.REACT_APP_ADMIN_DEVS)
        {canEdit = false}
    console.log("Rechte: ", canEdit)
    var canEditAuth = false;
    if (sessionStorage.getItem('auth')==process.env.REACT_APP_ADMIN_TEACHER 
        || sessionStorage.getItem('auth')==process.env.REACT_APP_ADMIN_DEVS)
        {canEditAuth = true}
            function arrayizer ()
            {
                var stroing = "";
                stroing = (document.getElementById("mulcats") as HTMLInputElement).value;
                var parray = stroing.split(",");
                return parray;
            }
            function calcTech () {
                var tDiv = 0;
                var t1 = 0;
                if (parseFloat((document.getElementById('t1') as HTMLInputElement).value))
                {t1 = parseFloat((document.getElementById('t1') as HTMLInputElement).value)
                tDiv += 0.2;
                };
                var t2 = 0;
                if (parseFloat((document.getElementById('t2') as HTMLInputElement).value))
                {t2 = parseFloat((document.getElementById('t2') as HTMLInputElement).value)
                tDiv += 0.3;
                };
                var t3 = 0;
                if (parseFloat((document.getElementById('t3') as HTMLInputElement).value))
                {t3 = parseFloat((document.getElementById('t3') as HTMLInputElement).value)
                tDiv += 0.5;
                }; 
                var t4 = 0;
                if (parseFloat((document.getElementById('t4') as HTMLInputElement).value))
                {t4 = parseFloat((document.getElementById('t4') as HTMLInputElement).value)
                tDiv += 0.5;
                };
                var t5 = 0;
                if (parseFloat((document.getElementById('t5') as HTMLInputElement).value))
                {t5 = parseFloat((document.getElementById('t5') as HTMLInputElement).value)
                tDiv += 0.5;
                };
                if (tDiv!=0){
                    var totalTech = ((t1*0.2+t2*0.3+t3*0.5+t4*0.5+t5*0.5)/tDiv).toFixed(2) //Gewichtung 2
                }
                else {
                    var totalTech = "0";
                }
                document.getElementById("tBewertung")!.innerHTML = "technische Kriterien: " + totalTech
                return totalTech
            }
            function calcOptik () {
                var oDiv = 0;
                var o1 = 0;
                if (parseFloat((document.getElementById('o1') as HTMLInputElement).value))
                {o1 = parseFloat((document.getElementById('o1') as HTMLInputElement).value)
                oDiv += 0.5;
                };
                var o2 = 0;
                if (parseFloat((document.getElementById('o2') as HTMLInputElement).value))
                {o2 = parseFloat((document.getElementById('o2') as HTMLInputElement).value)
                oDiv += 0.5;
                };
                var o3 = 0;
                if (parseFloat((document.getElementById('o3') as HTMLInputElement).value))
                {o3 = parseFloat((document.getElementById('o3') as HTMLInputElement).value)
                oDiv += 0.5;
                };            
                var o4 = 0;
                if (parseFloat((document.getElementById('o4') as HTMLInputElement).value))
                {o4 = parseFloat((document.getElementById('o4') as HTMLInputElement).value)
                oDiv += 0.5;
                };  
                if (oDiv!=0){
                    var totalOptik = ((o1*0.5+o2*0.5+o3*0.5+o4*0.5)/oDiv).toFixed(2) //Gewichtung 2
                }
                else {
                    var totalOptik = "0";
                }
                document.getElementById("oBewertung")!.innerHTML = "optische Kriterien: " + totalOptik 
                return totalOptik
            }

            function calcInhalt () {
                var iDiv = 0;
                var i1 = 0;
                if (parseFloat((document.getElementById('i1') as HTMLInputElement).value))
                { i1 = parseFloat((document.getElementById('i1') as HTMLInputElement).value)
                iDiv += 0.4;
                };
                var i2 = 0;
                if (parseFloat((document.getElementById('i2') as HTMLInputElement).value))
                {i2 = parseFloat((document.getElementById('i2') as HTMLInputElement).value)
                iDiv += 0.4;
                };
                var i3 = 0;
                if (parseFloat((document.getElementById('i3') as HTMLInputElement).value))
                {i3 = parseFloat((document.getElementById('i3') as HTMLInputElement).value)
                iDiv += 0.4;
                };            
                var i4 = 0;
                if (parseFloat((document.getElementById('i4') as HTMLInputElement).value))
                {i4 = parseFloat((document.getElementById('i4') as HTMLInputElement).value)
                iDiv += 0.3;
                };  
                var i5 = 0;
                if (parseFloat((document.getElementById('i5') as HTMLInputElement).value))
                {i5 = parseFloat((document.getElementById('i5') as HTMLInputElement).value)
                iDiv += 0.8;
                };
                var i6 = 0;
                if (parseFloat((document.getElementById('i6') as HTMLInputElement).value))
                {i6 = parseFloat((document.getElementById('i6') as HTMLInputElement).value)
                iDiv += 0.8;
                };
                var i7 = 0;
                if (parseFloat((document.getElementById('i7') as HTMLInputElement).value))
                {i7 = parseFloat((document.getElementById('i7') as HTMLInputElement).value)
                iDiv += 0.5;
                };            
                var i8 = 0;
                if (parseFloat((document.getElementById('i8') as HTMLInputElement).value))
                {i8 = parseFloat((document.getElementById('i8') as HTMLInputElement).value)
                iDiv += 0.4;
                };
                var i9 = 0;
                if (parseFloat((document.getElementById('i9') as HTMLInputElement).value))
                {i9 = parseFloat((document.getElementById('i9') as HTMLInputElement).value)
                iDiv += 0.2;
                };
                var i10 = 0;
                if (parseFloat((document.getElementById('i10') as HTMLInputElement).value))
                {i10 = parseFloat((document.getElementById('i10') as HTMLInputElement).value)
                iDiv += 0.8;
                };
                if (iDiv!=0){
                    var totalInhalt = ((i1*0.4+i2*0.4+i3*0.4+i4*0.3+i5*0.8+i6*0.8+i7*0.5+i8*0.4+i9*0.2+i10*0.8)/iDiv).toFixed(2) //Gewichtung 5
                }
                else {
                    var totalInhalt = "0";
                }
                document.getElementById("iBewertung")!.innerHTML = "Inhaltliche Kriterien: " + totalInhalt 
                return totalInhalt
            }

            function calcRechte () {
                var rDiv = 0;
                var r1 = 0;
                if (parseFloat((document.getElementById('r1') as HTMLInputElement).value))
                {r1 = parseFloat((document.getElementById('r1') as HTMLInputElement).value)
                rDiv += 0.4;
                };
                var r2 = 0;
                if (parseFloat((document.getElementById('r2') as HTMLInputElement).value))
                {r2 = parseFloat((document.getElementById('r2') as HTMLInputElement).value)
                rDiv += 0.4;
                };
                var r3 = 0;
                if (parseFloat((document.getElementById('r3') as HTMLInputElement).value))
                {r3 = parseFloat((document.getElementById('r3') as HTMLInputElement).value)
                rDiv += 0.2;
                };         
                if (rDiv!=0){
                    var totalRechte = ((r1*0.4+r2*0.4+r3*0.2)/rDiv).toFixed(2) //Gewichtung 1
                }
                else {
                    var totalRechte = "0";
                }    
                
                document.getElementById("rBewertung")!.innerHTML = "rechtliche Kriterien: " + totalRechte 
                return totalRechte
            }

            function calcGes () {
                var ges = ((parseFloat(calcRechte())+parseFloat(calcOptik())*2+parseFloat(calcInhalt())*5+parseFloat(calcTech())*2)/10).toFixed(2)
                return ges;
            }

            function checkRating()
            {
                if (!canEdit)
                {
                  /*
                    const form = document.querySelector(".form-total") as HTMLFormElement
                    
                    const statusForm = document.querySelector("#status") as HTMLSelectElement
                    form.addEventListener("submit", (e:Event) => {
                        e.preventDefault()
                        console.log("eventlistener"+ statusForm.value)
                        })
                        */
                var statusForm = (document.getElementById("status") as HTMLSelectElement)
                console.log(statusForm);
                console.log("statuswert:"+statusForm.value)
                return statusForm.value;
                }
                else {return "draft"}
            }
            
    return (
        <Edit title="Bearbeiten/Bewerten" saveButtonProps={saveButtonProps}>

                <Form className=".form-total" {...formProps} layout="vertical"
                            onFinish={(values) => {
                                formProps.onFinish?.({

                                    ...values,
                                    CustomCats: arrayizer(),
                                    RatingTechGes: calcTech(), 
                                    RatingOptikGes: calcOptik(),  
                                    RatingInhaltGes: calcInhalt(),
                                    RatingRechteGes: calcRechte(),
                                    RatingGesGes: calcGes(),    
                                    status: checkRating(),                                                                                              
                                }); 
                            }}>

                <Form.Item label="Titel" name="title" rules={[
                    {
                        required: true,
                    },
                ]}>
                    <Input disabled={canEditAuth}/>
                </Form.Item>

                <Form.Item label="Beschreibung" name="content" rules={[
                ]}>
                    <Input disabled={canEditAuth}/>
                </Form.Item>

                <h2> <img src="https://mirrors.creativecommons.org/presskit/icons/cc.xlarge.png" height="30"></img> -Lizenzen </h2>
                <a href="https://creativecommons.org/licenses/by-sa/4.0/legalcode.de"> mehr Informationen</a>
                
                <br></br>
                <h3>
                    <img src="https://mirrors.creativecommons.org/presskit/icons/zero.xlarge.png" height="20"></img>
                    Wenn Sie keine weitere Bedingungen für Ihr Modul festlegen, wird CC-Zero (C0) automatisch gesetzt.
                </h3>
                <br></br>
                <h3>
                    <img src="https://mirrors.creativecommons.org/presskit/icons/by.xlarge.png" height="20"></img> 
                    Namensnennung
                </h3>
                <Form.Item name="CC_BY">
                <Select disabled={canEditAuth} options={[
                        {
                            label: "Der Name des ursprünglichen Autors muss genannt werden",
                            value: "BY",
                        },
                        {
                            label: "-",
                            value: "",
                        },  
                    ]}
                    />
                </Form.Item>
                <h3>
                    <img src="https://mirrors.creativecommons.org/presskit/icons/nc.xlarge.png" height="20"></img>
                    nicht-kommerziell
                </h3>
                <Form.Item name="CC_NC">
                <Select disabled={canEditAuth} options={[
                        {
                            label: "Die Weiterverwendung darf nur nicht-kommerziellen Zwecken dienen",
                            value: "NC",
                        },
                        {
                            label: "-",
                            value: "",
                        },  
                    ]}
                    />
                </Form.Item>
                <h3>
                    <img src="https://mirrors.creativecommons.org/presskit/icons/nd.xlarge.png" height="20"></img>
                    keine Bearbeitung
                </h3>
                <Form.Item name="CC_ND">
                <Select disabled={canEditAuth} options={[
                        {
                            label: "Das Werk muss vollständig und ohne Veränderung bleiben",
                            value: "ND",
                        },
                        {
                            label: "-",
                            value: "",
                        },  
                    ]}
                    />
                </Form.Item>

                <h3>
                    <img src="https://mirrors.creativecommons.org/presskit/icons/sa.xlarge.png" height="20"></img>
                    Weitergabe unter gleichen Bedingungen
                </h3>
                <Form.Item name="CC_SA">
                <Select disabled={canEditAuth} options={[
                        {
                            label: "Bei Bearbeitung muss das neu entstandene Werk unter denselben Bedingungen veröffentlicht werden wie das ursprüngliche Werk",
                            value: "SA",
                        },
                        {
                            label: "-",
                            value: "",
                        },  
                    ]}
                    />
                </Form.Item>

                <Form.Item label="Kategorie" name="CategoryId" rules={[
                   
                ]}>
                    <Select disabled={canEditAuth} options={[
                        {
                            label: "Sonstige",
                            value: "Sonstige",
                        },
                        {
                            label: "Prüf- Messverfahren",
                            value: "Prüf- Messverfahren"
                        },
                        {
                            label: "Wirtschaft und Verwaltung",
                            value: "Wirtschaft und Verwaltung"
                        },
                        {
                            label: "Handwerk",
                            value: "Handwerk"
                        },
                        {
                            label: "Industrie",
                            value: "Industrie"
                        },
                        {
                            label: "Handel und Logistik",
                            value: "Handel und Logistik"
                        },
                        {
                            label: "IT und Steuerung",
                            value: "IT und Steuerung"
                        },
                    ]}/>
                </Form.Item>

                <Form.Item label="Unterkategorien" name="CustomCats" rules={[
                    
                ]}>
                    <Input disabled={canEditAuth} id="mulcats" />
                </Form.Item>

                <Form.Item label="Institut" name="Institut" rules={[
                   
                ]}>
                    <Select disabled={canEditAuth} options={[
                        {
                            label: "Öffentlich",
                            value: "public"
                        },
                        {
                            label: "Werkfeuerwehr Eisenhüttenstadt",
                            value: "Werkfeuerwehr Eisenhüttenstadt",
                        },
                        {
                            label: "OSZ Palmnicken",
                            value: "OSZ Palmnicken"
                        }
                    ]}/>
                </Form.Item>

                <h3>
                    Dritt Anbieter
                </h3>
                <Form.Item name="drittProviderBool">
                <Select disabled={canEditAuth} options={[
                        {
                            label: "Verwendet",
                            value: true,
                        },
                        {
                            label: "Nicht verwendet",
                            value: false,
                        },  
                    ]}
                    />
                </Form.Item>     

                <details>
                    <summary  > <b id="tBewertung">technische Kriterien: </b>  </summary>
                    <br></br>
                <Form.Item label="Kommentar" name="RatingTechComment">
                <Input disabled={canEdit}/>
                </Form.Item>
                <Form.Item label="barrierefrei x 0.2" name="RatingTechBarrierefreiheit" >
                    <input id="t1" onChange={(e)=> calcTech()}
                    disabled={canEdit} type="number" min="0" max="10"/>
                </Form.Item>
                <Form.Item label="Metadaten x 0.3" name="RatingTechMetadaten" >
                    <input id="t2" onChange={(e)=> calcTech()}
                    disabled={canEdit} type="number" min="0" max="10"/>
                </Form.Item>
                <Form.Item label="Zielformate x 0.5" name="RatingTechZielformate" >
                    <input id="t3" onChange={(e)=> calcTech()}
                    disabled={canEdit} type="number" min="0" max="10"/>
                </Form.Item>
                <Form.Item label="Funktionsfähig für Zielausgabe x 0.5" name="RatingTechFunktionsfaehigkeit" >
                    <input id="t4" onChange={(e)=> calcTech()}
                    disabled={canEdit} type="number" min="0" max="10"/>
                </Form.Item>
                <Form.Item label="Nutzbarkeit für Gerätetypen x 0.5" name="RaingTechNutzbarkeit" >
                    <input id="t5" onChange={(e)=> calcTech()}
                    disabled={canEdit} type="number" min="0" max="10"/>
                </Form.Item>
                </details>
                <br></br>

                <details>
                    <summary  > <b id="oBewertung">optische Kriterien: </b>  </summary>
                    <br></br>
                <Form.Item label="Kommentar" name="RatingOptikComment">
                <Input disabled={canEdit}/>
                </Form.Item>
                <Form.Item label="Typografie x 0.5" name="RatingOptikTypografie" >
                    <input id="o1" onChange={(e)=> calcOptik()}
                    disabled={canEdit} type="number" min="0" max="10"/>
                </Form.Item>
                <Form.Item label="Farbwahl x 0.5" name="RatingOptikFarbwahl" >
                    <input id="o2" onChange={(e)=> calcOptik()}
                    disabled={canEdit} type="number" min="0" max="10"/>
                </Form.Item>
                <Form.Item label="Format x 0.5" name="RatingOptikFormat" >
                    <input id="o3" onChange={(e)=> calcOptik()}
                    disabled={canEdit} type="number" min="0" max="10"/>
                </Form.Item>
                <Form.Item label="Audio /Ton x 0.5" name="RatingOptikAudio" >
                    <input id="o4" onChange={(e)=> calcOptik()}
                    disabled={canEdit} type="number" min="0" max="10"/>
                </Form.Item>
                </details>
                <br></br>

                <details>
                    <summary  > <b id="iBewertung">inhaltliche Kriterien: </b>  </summary>
                    <br></br>
                <Form.Item label="Kommentar" name="RatingInhaltComment">
                <Input disabled={canEdit}/>
                </Form.Item>
                <Form.Item label="Struktur x 0.4" name="RatingInhaltStruktur" >
                    <input id="i1" onChange={(e)=> calcInhalt()}
                    disabled={canEdit} type="number" min="0" max="10"/>
                </Form.Item>
                <Form.Item label="Navigation x 0.4" name="RatingInhaltNavigation" >
                    <input id="i2" onChange={(e)=> calcInhalt()}
                    disabled={canEdit} type="number" min="0" max="10"/>
                </Form.Item>
                <Form.Item label="Lernziele erkennbar x 0.4" name="RatingInhaltLernziel" >
                    <input id="i3" onChange={(e)=> calcInhalt()}
                    disabled={canEdit} type="number" min="0" max="10"/>
                </Form.Item>
                <Form.Item label="Umfang des Werkes x 0.3" name="RatingInhaltUmfang" >
                    <input id="i4" onChange={(e)=> calcInhalt()}
                    disabled={canEdit} type="number" min="0" max="10"/>
                </Form.Item>
                <Form.Item label="sachliche Richtigkeit x 0.8" name="RatingInhaltSachlich" >
                    <input id="i5" onChange={(e)=> calcInhalt()}
                    disabled={canEdit} type="number" min="0" max="10"/>
                </Form.Item>
                <Form.Item label="fachliche Richtigkeit x 0.8" name="RatingInhaltFachlich" >
                    <input id="i6" onChange={(e)=> calcInhalt()}
                    disabled={canEdit} type="number" min="0" max="10"/>
                </Form.Item>
                <Form.Item label="Verständlichkeit für die Zielgruppe" name="RatingInhaltVerstaendlichkeit" >
                    <input id="i7" onChange={(e)=> calcInhalt()}
                    disabled={canEdit} type="number" min="0" max="10"/>
                </Form.Item>
                <Form.Item label="Kombinierbarkeit mit anderen Themen x 0.4" name="RatingInhaltKombinierbarkeit" >
                    <input id="i8" onChange={(e)=> calcInhalt()}
                    disabled={canEdit} type="number" min="0" max="10"/>
                </Form.Item>
                <Form.Item label=" Hilfefunktion x 0.2" name="RatingInhaltHilfe" >
                    <input id="i9" onChange={(e)=> calcInhalt()}
                    disabled={canEdit} type="number" min="0" max="10"/>
                </Form.Item>
                <Form.Item label="formale Gestaltung x 0.8" name="RatingInhaltFormal" >
                    <input id="i10" onChange={(e)=> calcInhalt()}
                    disabled={canEdit} type="number" min="0" max="10"/>
                </Form.Item>
                </details>
                <br></br>

                <details>
                    <summary  > <b id="rBewertung">rechtliche Kriterien: </b>  </summary>
                    <br></br>
                <Form.Item label="Kommentar" name="RatingRechteComment">
                <Input disabled={canEdit}/>
                </Form.Item>
                <Form.Item label="CC – Lizenz angegeben x 0.4" name="RatingRechteLizenzAngegeben" >
                    <input id="r1" onChange={(e)=> calcRechte()}
                    disabled={canEdit} type="number" min="0" max="10"/>
                </Form.Item>
                <Form.Item label="ist die CC Lizenz berechtigt x 0.4" name="RatingRechteLizenzBerechtigt" >
                    <input id="r2" onChange={(e)=> calcRechte()}
                    disabled={canEdit} type="number" min="0" max="10"/>
                </Form.Item>
                <Form.Item label="Kontaktdaten zum Autor x 0.2" name="RatingRechteAutorkontakt" >
                    <input id="r3" onChange={(e)=> calcRechte()}
                    disabled={canEdit} type="number" min="0" max="10"/>
                </Form.Item>
                </details>
                <br></br>


                <Form.Item label="Neuer Status" name="status"  className="formx-status">
                <select id="status" disabled={canEdit}>
                    <option value="">--Please choose an option--</option>
                    <option value="draft">Draft</option>
                    <option value="published">Published</option>
                    <option value="rejected">Rejected</option>
                </select>
                </Form.Item>

                <Form.Item label="eduBB Synchronisation" name="edubbSync"  className="formx-status">
                <select id="edubbSync" disabled={canEdit}>
                    <option value="">--Please choose a status--</option>
                    <option value="Nicht synchronisert">Nicht synchronisert</option>
                    <option value="Synchronisiert">Synchronisiert</option>
                </select>
                </Form.Item>
            </Form>
        </Edit>
    );
};
