import { useShow, IResourceComponentsProps, useOne } from "@pankod/refine-core";
import { Container } from "components/container";
import {
    Show,
    Typography,
    MarkdownField,
    Space,
    ImageField,
    FileField,
} from "@pankod/refine-antd";

import {
    LockOutlined,
    LockTwoTone,
    UnlockOutlined
  } from '@ant-design/icons';

import { IPost, IFile } from "interfaces";
import { stringify } from "querystring";
const { Title, Text } = Typography;



export const PostShow: React.FC<IResourceComponentsProps> = () => {
    const { queryResult } = useShow<IPost>();
    const { data, isLoading } = queryResult;
    const record = data?.data;
    const images = record?.images ? (JSON.parse(record.images) as IFile[]) : [];
    function isCommented(rated?: string )
    {
        if (rated)
        {return rated}
        else 
        {return "kein Kommentar hinterlegt"}
    }
    function isRated(rated?: number )
    {
        if (rated)
        {return rated}
        else 
        {return "nicht bewertet"}
    }
    function check0(ccy?: string, ccc?: string, ccd?: string, cca?: string){
        if (!ccy && !ccc && !ccd && !cca){
            return <img src="https://mirrors.creativecommons.org/presskit/icons/zero.xlarge.png" height="20"></img>
        }
    }
    function checkBY(cc?: string){
        if (cc){
            return <img src="https://mirrors.creativecommons.org/presskit/icons/by.xlarge.png" height="20"></img>
        }
    }
    function checkNC(cc?: string){
        if (cc){
            return <img src="https://mirrors.creativecommons.org/presskit/icons/nc.xlarge.png" height="20"></img>
        }
    }
    function checkND(cc?: string){
        if (cc){
            return <img src="https://mirrors.creativecommons.org/presskit/icons/nd.xlarge.png" height="20"></img>
        }
    }
    function checkSA(cc?: string){
        if (cc){
            return <img src="https://mirrors.creativecommons.org/presskit/icons/sa.xlarge.png" height="20"></img>
        }
    }
    return (
        <Show isLoading={isLoading}>
        <div>
            <div>
                
                <Title level={5}>Title</Title>
                <Text>{record?.title}</Text>

                <Title level={5}>Author</Title>
                <Text>{record?.author}</Text>

                <Title level={5}>CC-Lizenzen</Title>
                {check0(record?.CC_BY, record?.CC_NC, record?.CC_ND, record?.CC_SA)}
                {checkBY(record?.CC_BY)}
                {checkNC(record?.CC_NC)}
                {checkND(record?.CC_ND)}
                {checkSA(record?.CC_SA)}
                
                <Title level={5}>Kategorie</Title>
                <Text>{record?.CategoryId.toString()}</Text>

                <Title level={5}>Unterkategorien</Title>
                <Text>{record?.CustomCats.toString()}</Text>

                <Title level={5}>Institut</Title>
                <Text>{record?.Institut.toString()}</Text>


                <Title level={5}>Status</Title>
                <Text>{ record?.status}</Text>

                <Title level={5}>Dritt Anbieter</Title>
                <Space wrap>
                {record?.drittProviderBool ? <div><Text>Dieses Modul verwendet Drittanbieter </Text><LockTwoTone /></div> : <div><Text>Dieses Modul verwendet keine Drittanbieter </Text><UnlockOutlined /></div>}
                </Space>

                <Title level={5}>Content</Title>
                <MarkdownField value={record?.content} />

                <Title level={5}>Images</Title>
                <Space wrap>
                    {record?.images ? (
                        images.map((img) => (
                            <ImageField
                                key={img.name}
                                value={img.url}
                                title={img.name}
                                width={200}
                            />
                        ))
                    ) : (
                        <Text>Not found any images</Text>
                    )}
                </Space>


                <Title level={5}> Im Browser ansehen</Title>
                <Space>           
                {record?.images ? (
                        images.map((img) => (
                            <FileField
                                key={img.name}
                                src={img.url}
                                title={img.name}
                                
                            />
                        ))
                    ) : (
                        <Text>Not found any images</Text>
                    )}
            
                </Space>
                <Title level={5}> Download Link</Title>
                <Space>           
                {record?.images ? (
                        images.map((img) => (
                            <FileField
                                key={img.name}
                                src={img.url?.replace("view", "download")}
                                title={img.name}
                                
                            />
                        ))
                    ) : (
                        <Text>Not found any images</Text>
                    )}
            
                </Space>


            </div>
            
            <Container styles={{ height: '100%', float: 'right', width: '50%', position: 'absolute', right: '0px', top: '0px'}}> 

            <div>        
                <h1>Bewertung: {record?.RatingGesGes}</h1>
                <details> 
                    <summary> <h3>technische Kriterien: {isRated(record?.RatingTechGes)}</h3></summary> 
                        <h4>Kommentar: </h4>
                        <Text>{isCommented(record?.RatingTechComment)}</Text> 
                        <h4>Barrierefreiheit:</h4> 
                        <Text>{isRated(record?.RatingTechBarrierefreiheit)}</Text> 
                        <h4>Metadaten: </h4>
                        <Text>{isRated(record?.RatingTechMetadaten)}</Text>  
                        <h4>Zielformate: </h4>
                        <Text>{isRated(record?.RatingTechZielformate)}</Text>  
                        <h4>Funktionsfähigkeit </h4>
                        <Text>{isRated(record?.RatingTechFunktionsfaehigkeit)}</Text>  
                        <h4>Nutzbarkeit: </h4>
                        <Text>{isRated(record?.RaingTechNutzbarkeit)}</Text>                         
                </details> 
                <details> 
                    <summary> <h3>optische Kriterien - Design und Lesbarkeit: {isRated(record?.RatingOptikGes)}</h3></summary> 
                        <h4>Kommentar: </h4>
                        <Text>{isCommented(record?.RatingOptikComment)}</Text> 
                        <h4>Typografie:</h4> 
                        <Text>{isRated(record?.RatingOptikTypografie)}</Text> 
                        <h4>Farbwahl: </h4>
                        <Text>{isRated(record?.RatingOptikFarbwahl)}</Text>  
                        <h4>Format: </h4>
                        <Text>{isRated(record?.RatingOptikFormat)}</Text>  
                        <h4>Audio </h4>
                        <Text>{isRated(record?.RatingOptikAudio)}</Text>                         
                </details>   
                <details> 
                    <summary> <h3>Inhalt: {isRated(record?.RatingInhaltGes)}</h3></summary> 
                        <h4>Kommentar: </h4>
                        <Text>{isCommented(record?.RatingInhaltComment)}</Text> 
                        <h4>Struktur:</h4> 
                        <Text>{isRated(record?.RatingInhaltStruktur)}</Text> 
                        <h4>Navigation: </h4>
                        <Text>{isRated(record?.RatingInhaltNavigation)}</Text>  
                        <h4>Lernziel: </h4>
                        <Text>{isRated(record?.RatingInhaltLernziel)}</Text>  
                        <h4>Umfang </h4>
                        <Text>{isRated(record?.RatingInhaltUmfang)}</Text>  
                        <h4>Sachliche Richtigkeit: </h4>
                        <Text>{isRated(record?.RatingInhaltSachlich)}</Text>  
                        <h4>Fachliche Richtigkeit: </h4>
                        <Text>{isRated(record?.RatingInhaltFachlich)}</Text>  
                        <h4>Verständlichkeit: </h4>
                        <Text>{isRated(record?.RatingInhaltVerstaendlichkeit)}</Text>  
                        <h4>Kombinierbarkeit: </h4>
                        <Text>{isRated(record?.RatingInhaltKombinierbarkeit)}</Text>  
                        <h4>Hilfefunktionen: </h4>
                        <Text>{isRated(record?.RatingInhaltHilfe)}</Text> 
                        <h4>formale Gestaltung: </h4>
                        <Text>{isRated(record?.RatingInhaltFormal)}</Text>                        
                </details>   
                <details> 
                    <summary> <h3>technische Kriterien: {isRated(record?.RatingTechGes)}</h3></summary> 
                        <h4>Kommentar: </h4>
                        <Text>{isCommented(record?.RatingRechteComment)}</Text> 
                        <h4>Barrierefreiheit:</h4> 
                        <Text>{isRated(record?.RatingRechteLizenzAngegeben)}</Text> 
                        <h4>Metadaten: </h4>
                        <Text>{isRated(record?.RatingRechteLizenzBerechtigt)}</Text>  
                        <h4>Zielformate: </h4>
                        <Text>{isRated(record?.RatingRechteAutorkontakt)}</Text>  
                                                
                </details>            
            </div>

            </Container>
        </div>
        </Show>
    );
};