import React, { useState } from "react";


import { IResourceComponentsProps, HttpError} from "@pankod/refine-core";
import {
    Create,
    Form,
    List,
    Button,
    Table,
    useTable,
    Input,
    Select,
    Upload,
    useForm,
    useSelect,
    UrlField,
    RcFile,
    getValueFromEvent,
    TextField
} from "@pankod/refine-antd";

import ReactMarkdown from "react-markdown";
//import ReactMde from "react-mde";


import { useGetIdentity } from "@pankod/refine-core";
import { IPost, IFile, IPostVariables} from "interfaces";
import { appwriteClient, storage } from "utilities/appwriteClient";
import { normalizeFile } from "utilities/normalize";
import { idText } from "typescript";
import { url } from "inspector";
import { timeStamp } from "console";



export const PostCreate: React.FC<IResourceComponentsProps> = ()=> {
    let usermail = "default";
    if (sessionStorage.getItem("auth"))
    {usermail = sessionStorage.getItem("auth")!}
    const { formProps, saveButtonProps, redirect } = useForm<IPost,  HttpError, IPostVariables>();
    function datum ()
    {
        var now = (new Date()).toUTCString();
        return now;

    }
    function arrayizer ()
    {
        var stroing = "";
        stroing = (document.getElementById("mulcats") as HTMLInputElement).value;
        var parray = stroing.split(",");
        return parray;

    }
    return (
        <Create saveButtonProps={saveButtonProps}>
                       
            <Form {...formProps} layout="vertical"
                            onFinish={(values) => {
                                formProps.onFinish?.({
                                    ...values,                               
                                    status: "draft",
                                    author: usermail,
                                    images: JSON.stringify(values.images),
                                    CustomCats: arrayizer(),
                                    date: datum()
                                });  
                            }}
            >
                <Form.Item label="Titel" name="title" rules={[
                    {
                        required: true,
                    },
                ]}
                >
                    <Input placeholder="enter a name for this module"/>
                </Form.Item>

                <Form.Item label="Beschreibung" name="content" rules={[

                ]}
                >
                    <Input placeholder="Fügen Sie eine kurze Modulbeschreibung hinzu"/>
                </Form.Item>
                              
                <Form.Item label="Kategorie" name="CategoryId" rules={[
                    {
                        required: true,
                    },
                ]}
                >
                    <Select options={[
                        {
                            label: "Sonstige",
                            value: "Sonstige",
                        },
                        {
                            label: "Prüf- Messverfahren",
                            value: "Prüf- Messverfahren"
                        },
                        {
                            label: "Wirtschaft und Verwaltung",
                            value: "Wirtschaft und Verwaltung"
                        },
                        {
                            label: "Handwerk",
                            value: "Handwerk"
                        },
                        {
                            label: "Industrie",
                            value: "Industrie"
                        },
                        {
                            label: "Handel und Logistik",
                            value: "Handel und Logistik"
                        },
                        {
                            label: "IT und Steuerung",
                            value: "IT und Steuerung"
                        }, 
                    ]}
                    />
                </Form.Item>

                <Form.Item label="Unterkategorie" >
                <Input id="mulcats" placeholder="trennen Sie die eingaben mit ',' - z.B Oberstufe,Klausurvorbereitung,lernen" />
                </Form.Item>

                <Form.Item label="Institut" name="Institut" rules={[
                    {
                        required: true,
                    },
                ]}
                >
                    <Select options={[
                        {
                            label: "Öffentlich",
                            value: "public"
                        },
                        {
                            label: "Werkfeuerwehr Eisenhüttenstadt",
                            value: "Werkfeuerwehr Eisenhüttenstadt",
                        },
                        {
                            label: "OSZ Palmnicken",
                            value: "OSZ Palmnicken"
                        }
                    ]}
                    />
                </Form.Item>

                <h2> <img src="https://mirrors.creativecommons.org/presskit/icons/cc.xlarge.png" height="30"></img> -Lizenzen </h2>
                <a href="https://creativecommons.org/licenses/by-sa/4.0/legalcode.de"> mehr Informationen</a>
                
                <br></br>
                <h3>
                    <img src="https://mirrors.creativecommons.org/presskit/icons/zero.xlarge.png" height="20"></img>
                    Wenn Sie keine weitere Bedingungen für Ihr Modul festlegen, wird CC-Zero (C0) automatisch gesetzt.
                </h3>
{/*                 <Form.Item name="CC_0">
                <Select options={[
                        {
                            label: "CC0-Inhalte können ohne Nachfrage zu beliebigen Zwecken kopiert, veröffentlicht oder auf andere Weise verwendet werden",
                            value: "0",
                        },
                        {
                            label: "-",
                            value: "",
                        },  
                    ]}
                    />
                </Form.Item> */}
                <h3>
                    <img src="https://mirrors.creativecommons.org/presskit/icons/by.xlarge.png" height="20"></img> 
                    Namensnennung
                </h3>
                <Form.Item name="CC_BY">
                <Select options={[
                        {
                            label: "Der Name des ursprünglichen Autors muss genannt werden",
                            value: "BY",
                        },
                        {
                            label: "-",
                            value: "",
                        },  
                    ]}
                    />
                </Form.Item>
                <h3>
                    <img src="https://mirrors.creativecommons.org/presskit/icons/nc.xlarge.png" height="20"></img>
                    nicht-kommerziell
                </h3>
                <Form.Item name="CC_NC">
                <Select options={[
                        {
                            label: "Die Weiterverwendung darf nur nicht-kommerziellen Zwecken dienen",
                            value: "NC",
                        },
                        {
                            label: "-",
                            value: "",
                        },  
                    ]}
                    />
                </Form.Item>
                <h3>
                    <img src="https://mirrors.creativecommons.org/presskit/icons/nd.xlarge.png" height="20"></img>
                    keine Bearbeitung
                </h3>
                <Form.Item name="CC_ND">
                <Select options={[
                        {
                            label: "Das Werk muss vollständig und ohne Veränderung bleiben",
                            value: "ND",
                        },
                        {
                            label: "-",
                            value: "",
                        },  
                    ]}
                    />
                </Form.Item>

                <h3>
                    <img src="https://mirrors.creativecommons.org/presskit/icons/sa.xlarge.png" height="20"></img>
                    Weitergabe unter gleichen Bedingungen
                </h3>
                <Form.Item name="CC_SA">
                <Select options={[
                        {
                            label: "Bei Bearbeitung muss das neu entstandene Werk unter denselben Bedingungen veröffentlicht werden wie das ursprüngliche Werk",
                            value: "SA",
                        },
                        {
                            label: "-",
                            value: "",
                        },  
                    ]}
                    />
                </Form.Item>

                <h3>
                    Dritt Anbieter
                </h3>

                <Form.Item name="drittProviderBool">
                <Select options={[
                        {
                            label: "Verwendet",
                            value: true,
                        },
                        {
                            label: "Nicht verwendet",
                            value: false,
                        },  
                    ]}
                    />
                </Form.Item>                

                <Form.Item label="Images">
                    <Form.Item
                        name="images"
                        valuePropName="fileList"
                        normalize={normalizeFile}
                        noStyle
                    >
                        <Upload.Dragger
                            name="file"
                            listType="picture"
                            multiple
                            customRequest={async ({
                                file,
                                onError,
                                onSuccess,
                            }) => {
                                try {
                                    const rcFile = file as RcFile;

                                    const { $id } =
                                        await storage.createFile(
                                            "62df9ed9a11fead9071d",
                                            //rcFile.name.slice(0,35),
                                            Date.now().toString(),
                                            rcFile,
                                        );

                                    const url =
                                        storage.getFileView(
                                            "62df9ed9a11fead9071d",
                                            $id,
                                        );
                                    
                                    onSuccess?.({ url }, new XMLHttpRequest());
                                } catch (error) {
                                    onError?.(new Error("Upload Error"));
                                }
                            }}
                        >
                            <p className="ant-upload-text">
                                legen Sie die Dateien für den Upload hier ab
                            </p>
                        </Upload.Dragger>
                    </Form.Item>
                </Form.Item>
            </Form>
        </Create>
        
    )
}