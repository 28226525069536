import React from "react";
import { useTitle, useLogout} from "@pankod/refine-core";
import { Icons, Menu, useMenu } from "@pankod/refine-antd";
import routerProvider from "@pankod/refine-react-router-v6";
import { useGetIdentity } from "@pankod/refine-core";
import { Login } from "pages/login/login";
const { Link } = routerProvider;

let Role = "Autor"
function getRole ()
{
if (process.env.REACT_APP_ADMIN_DEVS == sessionStorage.getItem('auth') || sessionStorage.getItem('auth') == process.env.REACT_APP_ADMIN_TEACHER)
{
    Role = "Admin"
    return false;
}
else {
    Role = "Autor"
    return true;
}
}
export const CustomSider: React.FC = () => {
    const Title = useTitle();
    const { menuItems, selectedKey } = useMenu();
    const { mutate: logout } = useLogout();
    
    const User: React.FC = () => {
    const { data: identity } = useGetIdentity<{ id: number; fullName: string}>();
    return <span>{identity?.fullName}</span>
    }
    
    return (
        <>
            {Title && <Title collapsed={false} />}
            <Menu selectedKeys={[selectedKey]} mode="horizontal">
                {menuItems.map(({ icon, route, label }) => (
                    <Menu.Item key={route} icon={icon}>
                        <Link to={route ?? ""}>{label}</Link>
                    </Menu.Item>
                ))}
                <Menu.Item key="logout" onClick={() => logout()} icon={<Icons.LogoutOutlined />}>Logout</Menu.Item>
                <Menu.Item key="loggedinas" >Sie sind eingeloggt als: {sessionStorage.getItem('auth')} </Menu.Item>
                <Menu.Item key="loggedinwith"> Rolle: {Role}</Menu.Item>
                <Menu.Item key="userboard" hidden={getRole()}> <a href="/userboard">Nutzerverzeichnis </a> </Menu.Item>
            </Menu>
        </>
    )
}